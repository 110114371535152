<script lang="ts" setup>
import type { ClaimFiltered } from "~/types";

const { claim } = defineProps<{
  claim: ClaimFiltered;
}>();

const route = useRoute();
const active = computed(() => route.path.includes(claim.id));
const shortId = useSubmissionShortId(computed(() => claim.shortId));

const originUrl = computed(() => getFirstDefinedField(claim.origins, "url"));
const originFile = computed(() => getFirstOriginImage(claim.origins));

const claimStore = useClaimStore();
</script>

<template>
  <li>
    <NuxtLink
      :class="
        cn(
          'px-3 pt-2 pb-2.5 flex flex-col gap-2 transition group',
          'group bg-neutral-0 border hover:border-neutral-4 hover:bg-neutral-05 rounded-xl',
          {
            '!border-online': active
          }
        )
      "
      :to="`/submissions/${route.params.filter}/${claim.id}${route.query.search ? `?search=${route.query.search}` : ''}`"
      @click="claimStore.setClaim(claim, 'submissions')"
    >
      <div class="flex items-center justify-between text-sm gap-2 tracking-wide">
        <div class="flex gap-2 items-center">
          <UnreadIcon :hasRead="claim.userClaimStatuses[0]?.hasRead" />
          <h3 class="text-sm uppercase font-bold">{{ shortId.short }}</h3>
        </div>
        <div class="ml-auto flex gap-1">
          <ClaimButtonsInternalButton :claimId="claim.id" :internal="claim.internal" size="xs" />
          <ClaimButtonsUserStatusButtons
            v-if="claim.id"
            :claimId="claim.id"
            :userStatus="claim.userClaimStatuses[0]"
            size="xs"
            showHasRead
          />
        </div>
      </div>
      <div
        v-if="!claim.submitterNotes && originUrl"
        :class="cn('w-full flex bg-neutral-1 gap-2.5 px-2.5 py-2 rounded-md')"
      >
        <p class="font-mono text-xs break-all line-clamp-3">{{ originUrl }}</p>
      </div>
      <div v-if="originFile" class="overflow-hidden rounded-lg aspect-[3/1]">
        <BasicImage
          v-bind="originFile"
          :class="
            cn('w-full h-full object-cover opacity-80 transition', {
              'opacity-100': active
            })
          "
          sizes="max-3xl:193px 273px"
        />
      </div>
      <p
        v-if="claim.submitterNotes"
        class="font-serif text-sm leading-[1.3] -mt-0.5 hyphens-auto line-clamp-3"
      >
        {{ claim.submitterNotes }}
      </p>
      <p
        v-else-if="claim.synopsis"
        class="font-serif text-sm leading-[1.3] -mt-0.5 hyphens-auto line-clamp-3"
      >
        {{ claim.synopsis }}
      </p>
      <CategoriesCard :categories="claim.categories" />
    </NuxtLink>
  </li>
</template>
