<script lang="ts" setup>
const scrollWrapper = ref();
const document = ref();

const route = useRoute();
function scrollToDocument() {
  nextTick(() => {
    const rect = document.value.getBoundingClientRect();
    scrollWrapper.value?.scrollTo({ left: rect.left, behavior: "smooth" });
  });
}
onMounted(() => scrollToDocument());
watch(() => route.fullPath, scrollToDocument);

usePageNavigation(document, "document");
</script>

<template>
  <div
    ref="scrollWrapper"
    :class="
      cn(
        'h-[100dvh] w-full relative flex bg-neutral-1',
        'overflow-x-auto overflow-y-hidden xl:overflow-hidden',
        'max-xl:snap-x max-xl:snap-mandatory',
        'max-xl:py-12'
      )
    "
  >
    <Navigation />
    <ClaimList />
    <section
      id="document"
      ref="document"
      :class="
        cn(
          'w-[100vw] shrink-0 sm:max-xl:w-[50vw] xl:basis-0 xl:grow h-full flex flex-col border-r border-neutral-4',
          'max-xl:snap-start @container'
        )
      "
    >
      <NuxtPage />
    </section>
    <ChatRoom :title="$t('channel.title')" class="w-[100vw] sm:max-xl:w-[50vw]" />
  </div>
</template>
